export const getPaddingRatio = (ratios = {}, device) => {
  const defaultDevice = ratios[device] || ratios.mobile || ratios.desktop || {};
  const { ratio } = defaultDevice;

  if (!ratio || ratio === '0:0') {
    return '';
  }

  const splitted = ratio.split(':');
  return `${(splitted[1] / splitted[0]) * 100}%`;
};

export const getDefaultSource = sources =>
  sources.mobile && sources.mobile.src !== undefined ? sources.mobile : sources.desktop;
export const getPaddingTopStyled = (sources, type) => {
  const height = sources[type]?.height;
  const paddingTop = getPaddingRatio(sources, type);
  return height ? `min(${paddingTop}, ${height}px)` : `${paddingTop}`;
};
