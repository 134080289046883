import React from 'react';
import compose from 'lodash/fp/compose';
import { withCookies } from '@catalogo/core-connect/cookies';
import { withConfig } from '@catalogo/core-connect/config';
import { withMetrics } from '@catalogo/core-metrics';
import { getCartCookieNameWithConsumptionType } from '@catalogo/service-empresas-cart/src/helpers/cart';
import { fmtCart } from '../helpers/cart';
import { useGetCart } from '../hooks/get-cart';

export const withCart = Component => props => {
  const cartId = getCartCookieNameWithConsumptionType(props?.config, props?.cookies);
  const { data, loading, error } = useGetCart(props?.cookies?.[cartId]);

  const products = fmtCart(data);
  const cartSize = products.reduce((size, product) => size + product.quantity, 0);
  const cartProps = {
    products,
    loading,
    error,
    cartSize,
    total: data?.cart?.total,
  };

  return <Component {...cartProps} {...props} />;
};

export default compose(withMetrics, withConfig, withCookies, withCart);
