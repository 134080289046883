import { getIn } from 'seamless-immutable';
import { sendBeacon } from '@catalogo/core-send-beacon';

const sendMetricsAds = product => {
  if (!product) {
    return null;
  }

  const { trackingUrl } = product;
  if (trackingUrl) {
    sendBeacon(trackingUrl);
  }
};
const sendMetrics = (product, position, index, dispatchMetrics) => {
  if (!product) {
    return null;
  }
  const { id, offers } = product;

  const prime = getIn(offers, ['result', 0, 'stamps', 'prime']);
  const bestPaymentOption = getIn(offers, ['result', 0, 'bestPaymentOption']);
  const salesPrice = getIn(bestPaymentOption, ['minInstallment', 0, 'total']) || getIn(bestPaymentOption, ['price']);
  const oneDayDelivery = getIn(offers, ['result', 0, 'stamps', 'oneDayDelivery'], []);
  const offerId = getIn(offers, ['result', 0, 'offerId'], '');

  if (product?.ads) {
    sendBeacon(product?.ads?.url);
  }

  dispatchMetrics('product:click', { position, id, index, salesPrice, prime, oneDayDelivery, offerId });
};

export const sendMetricsBrand = (dispatchMetrics, sponsorName) => {
  dispatchMetrics('event:track', {
    event: 'brandingbusca',
    category: 'branding-busca',
    label: sponsorName,
    action: 'clique',
  });
};

export const handleMetrics = (product, position, index, dispatchMetrics, brand, sponsorName) => {
  !brand && sendMetrics(product, position, index, dispatchMetrics);
  sendMetricsAds(product);
  !!brand && sendMetricsBrand(dispatchMetrics, sponsorName);
};
