import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import PriceInfo from '../molecules/price-info';
import ImageInfo from '../molecules/image-info';
import ProductInfo from '../molecules/product-info';

const InStockCard = props => (
  <Wrapper>
    <Nav to={props.url}>
      <ImageInfo {...props} />
      <ProductInfo {...props} />
      <PriceInfo {...props} />
    </Nav>
  </Wrapper>
);

const Wrapper = styled.div``;

export default InStockCard;
