import React, { useEffect, useState } from 'react';
import PurposeIcon from '@catalogo/ui-empresas-desktop-svg/header-purpose.svg';
import styled from 'styled-components';
import Tooltip from '@catalogo/theme-tooltip-box';
import ArrowDown from '@catalogo/ui-americanas-desktop-svg/arrow.svg';
import ToolTipConsumptionType from './components/tooltip-consumption-type';

export const ConsumptionType = ({ history, setCookie, route, cookies = {} }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { consumptionType } = cookies;

  const consumptionTypeOptions = {
    RESALE: 'somente revenda',
    OWN: 'somente uso consumo',
  };

  useEffect(() => {
    setShowTooltip(false);
  }, [cookies.consumptionType]);

  return (
    <Container>
      <Wrapper
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        zIndex={showTooltip ? 9 : 0}
      >
        <PurposeIconUi />
        <ConsumptionTypeText>
          finalidade de compra:
          <PurchasePurposeSelected>
            {consumptionTypeOptions[consumptionType] || 'nenhum selecionado'}
          </PurchasePurposeSelected>
        </ConsumptionTypeText>
        <ArrowDownUI />
      </Wrapper>
      <Tooltip
        width="auto"
        height="auto"
        show={showTooltip}
        positionType="absolute"
        color="#fff"
        arrowSize={12}
        marginTop={0}
        arrowPosition={consumptionType === 'OWN' ? '89%' : '88%'}
        top="45px"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        zIndex={showTooltip ? 9 : 0}
        beforeSpacing="5px"
      >
        {showTooltip && (
          <ToolTipConsumptionType
            history={history}
            setCookie={setCookie}
            routeType={route?.type}
            zIndex={showTooltip ? 9 : 0}
          />
        )}
      </Tooltip>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  font-size: 14px;
  color: #35002a;
  height: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: ${({ zIndex }) => zIndex};
`;

const PurposeIconUi = styled(PurposeIcon)`
  height: 20px;
  margin-right: 10px;
  fill: ${({ theme }) => theme.primaryColor};
`;

const ConsumptionTypeText = styled.span`
  white-space: nowrap;
  margin-right: 4px;
`;

const PurchasePurposeSelected = styled.span`
  font-weight: 700;
  padding-left: 10px;
`;
const ArrowDownUI = styled(ArrowDown)`
  height: 5px;
  width: 10px;
  fill: #333;
  margin-right: 22px;
  transition: fill 0.3s, transform 0.3s;
`;
