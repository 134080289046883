import React from 'react';
import styled from 'styled-components';
import Logo from './logo';
import Search from './search';
import Login from './login';
import IconMenu from './icon-menu';

const TopHeader = props => (
  <Container>
    <Logo {...props} />
    <Search {...props} />
    <Login {...props} />
    <IconMenu />
  </Container>
);

export default TopHeader;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 19px 0;
  align-items: center;
`;
