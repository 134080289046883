import { useQuery } from '@apollo/client';
import { getCart } from '../queries/get-cart.graphql';

export const useGetCart = cartId => {
  const { loading, data, error } = useQuery(getCart, {
    variables: {
      cartId,
    },
    skip: !cartId,
    ssr: false,
  });

  return { loading, data, error };
};
