import React from 'react';
import styled, { css } from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import Banner from './banner';

const ListItem = ({ title, link, dispatchMetrics }) => {
  if (title.length <= 0 || link.length <= 0) {
    return null;
  }

  return (
    <Item key={title}>
      <Link
        to={link}
        onClick={() =>
          dispatchMetrics('event:track', {
            event: 'c:click',
            action: 'click',
            category: 'menu-tts',
            label: title,
          })
        }
      >
        {title}
      </Link>
    </Item>
  );
};

export const TrendingDepartmentsDropDown = ({
  list = [],
  title,
  link,
  show,
  position = 'left',
  image,
  imageUrl,
  alt,
  dispatchMetrics,
}) => (
  <Container position={position} show={show}>
    <Wrapper>
      <List>
        <Item full={true}>
          <Link
            to={link}
            onClick={() =>
              dispatchMetrics('event:track', {
                event: 'c:click',
                action: 'click',
                category: 'menu-tts',
                label: title,
              })
            }
          >{`ver tudo em ${title}`}</Link>
        </Item>
        <WrapperList>
          <ListItems count={list.length > 7 ? 2 : 1}>
            {list.map(({ title, link }) => (
              <ListItem key={title} title={title} link={link} dispatchMetrics={dispatchMetrics} />
            ))}
          </ListItems>
        </WrapperList>
      </List>
      <BannerUI image={image} link={imageUrl} alt={alt} />
    </Wrapper>
  </Container>
);

export default TrendingDepartmentsDropDown;

const left = () =>
  css`
    left: 0;
  `;
const right = () =>
  css`
    right: 0;
  `;

const Container = styled.div`
  ${({ position }) => css`
    background-color: #fafafa;
    position: absolute;
    top: 46px;
    ${position === 'left' ? left() : right()}
    border-radius: 0px 0px 16px 16px;
    width: auto;
    height: 344px;
    max-width: 688px;
    min-width: 536px;
    box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.12);
    visibility: hidden;

    ${({ show }) =>
      show &&
      css`
        visibility: visible;
      `}
  `}
`;

const Wrapper = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
  position: relative;
  padding: 32px 24px 24px 24px;

  &::after {
    content: '';
    height: 1px;
    background-color: #ccc;
    width: calc(100% - 40px);
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    pointer-events: none;
  }
`;

const WrapperList = styled.div`
  height: 100%;
`;

const List = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ListItems = styled.ul`
  ${({ count }) => css`
    column-count: ${count};
    column-fill: auto;
    column-gap: 8px;
  `}
`;

const Item = styled.li`
  ${({ full }) => css`
    white-space: nowrap;
    color: #666;
    font-size: 14px;
    line-height: 18px;
    padding: 7px 0;
    width: 208px;
    break-inside: avoid;
    ${full &&
    css`
      flex: 0 0 auto;
      width: 100%;
    `}
  `}
`;

const Link = styled(Nav)`
  color: #666;

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
    text-decoration: none;
  }
`;

const BannerUI = styled(Banner)`
  margin-left: 24px;
`;
