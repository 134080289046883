import { formatPrice } from '@catalogo/core-helpers/price';
import { getIn } from 'seamless-immutable';

const getBestPaymentOption = product => {
  const offers = getIn(product, ['offers', 'result', 0], {});
  const bestPaymentOption = getIn(offers, ['bestPaymentOption']);
  const price = getIn(bestPaymentOption, ['price']);
  const type = getIn(bestPaymentOption, ['type']);
  const cashback = getIn(bestPaymentOption, ['cashback']);
  const cashBackValue = getIn(bestPaymentOption, ['minInstallment', 0, 'cashback', 'value']);
  const minInstallment = getIn(bestPaymentOption, ['minInstallment', 0]);
  const maxInstallment = getIn(bestPaymentOption, ['maxInstallment', 0]);
  const discountBestPayment = getIn(bestPaymentOption, ['discount', 'rate'], 0);

  return { minInstallment, maxInstallment, type, cashback, cashBackValue, price, discountBestPayment };
};

export const getInstallmentPhrase = product => {
  const { type, maxInstallment, minInstallment, price } = getBestPaymentOption(product);

  if (type === 'BOLETO') {
    return 'no boleto';
  }

  if (type === 'PIX') {
    return 'com pix';
  }

  const cardType = type === 'CARTAO_VISA' ? 'cartão de crédito' : 'cartão de crédito Ame';

  if (minInstallment && minInstallment.value < price) {
    return `em 1x no ${cardType}`;
  }

  if (maxInstallment) {
    return `${maxInstallment.quantity}x de R$ ${formatPrice(maxInstallment.value)} sem juros no ${cardType}`;
  }

  return null;
};

export const getPrice = (product, isNurslingProduct) => {
  if (isNurslingProduct) {
    return null;
  }

  const offers = getIn(product, ['offers', 'result', 0], {});
  const salesPrice = getIn(offers, ['salesPrice']);
  const listPrice = getIn(offers, ['listPrice']);
  const { minInstallment } = getBestPaymentOption(product);

  if (minInstallment && minInstallment.value < salesPrice) {
    return `R$ ${formatPrice(salesPrice)}`;
  }

  return listPrice ? `R$ ${formatPrice(listPrice)}` : null;
};

export const getPromotionalPrice = product => {
  const offers = getIn(product, ['offers', 'result', 0], {});
  const salesPrice = getIn(offers, ['salesPrice']);
  const { price, minInstallment } = getBestPaymentOption(product);

  if (minInstallment && minInstallment.value < salesPrice) {
    return `R$ ${formatPrice(minInstallment.value)}`;
  }

  return `R$ ${formatPrice(price)}`;
};

export const getDiscountRateText = rate => (rate >= 5 ? `${rate}%` : 'baixou');

export const getDiscount = (product, isNurslingProduct) => {
  if (isNurslingProduct) {
    return null;
  }

  const offers = getIn(product, ['offers', 'result', 0], {});
  const { minInstallment, discountBestPayment } = getBestPaymentOption(product);
  const minInstallmentRate = getIn(minInstallment, ['discount', 'rate'], 0);
  const offerDiscountRate = getIn(offers, ['discount', 'rate'], 0);

  if (minInstallmentRate && minInstallmentRate > 0) {
    return getDiscountRateText(minInstallmentRate);
  }

  if (discountBestPayment && discountBestPayment > 0) {
    return getDiscountRateText(discountBestPayment);
  }

  if (offerDiscountRate && offerDiscountRate > 0) {
    return getDiscountRateText(offerDiscountRate);
  }

  return null;
};

export const getCashBack = (product, isNurslingProduct) => {
  if (isNurslingProduct) {
    return null;
  }

  const { cashback, cashBackValue } = getBestPaymentOption(product);

  if (cashback && cashback.value > 0 && cashback.isSuperCashback) {
    return `R$ ${formatPrice(cashBackValue)}`;
  }
  return null;
};
