import React from 'react';
import styled from 'styled-components';
import Arrow from '@catalogo/ui-americanas-desktop-svg/arrow.svg';
import { useHeaderContext, setHeaderContext } from '@catalogo/service-empresas-header/src/context/header';

const ContextSearch = ({ className }) => {
  const { name, context } = useHeaderContext();
  const options = [name, 'Americanas'];
  const handleSeleted = e => {
    setHeaderContext({
      context: e.currentTarget.value,
    });
  };

  return (
    <ContextContainer>
      <SelectUI onChange={handleSeleted} className={className}>
        {options.map(option => (
          <option key={option} value={option} selected={option === context}>
            {option}
          </option>
        ))}
      </SelectUI>
      <ArrowUI />
    </ContextContainer>
  );
};

export default ContextSearch;

const ContextContainer = styled.div`
  position: relative;
  background: #e5e5e5;
  border-radius: 8px 0 0 8px;

  min-width: 176px;
`;

const SelectUI = styled.select`
  appearance: none;
  background: none;
  padding: 12px 20px 12px 15px;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  color: ${({ theme }) => theme.grey.dark};
`;

const ArrowUI = styled(Arrow)`
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  height: 6px;
  width: 11px;
  fill: ${({ theme }) => theme.grey.medium};
`;
