import React from 'react';
import styled, { css } from 'styled-components';
import ProductCard from '@catalogo/ui-americanas-desktop-product-card-suggestion';

const ProductList = ({ products, className }) => (
  <Wrapper className={className}>
    <Title>Produtos sugeridos:</Title>
    <Container>
      {products
        .filter(product => !!product.product)
        .slice(0, 3)
        .map(product => (
          <Card key={product.product.id}>
            <ProductCard className="product-card-header" product={product} />
          </Card>
        ))}
    </Container>
  </Wrapper>
);

export default ProductList;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  overflow: auto;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  align-items: baseline;
  align-content: stretch;
`;

const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.grey.dark}};
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    flex: 0 0 auto;
    padding: 10px 8px;
    width: 100%;
    margin: 0;
  `}
`;
const Card = styled.div`
  width: 33.33%;

  & .product-card-header {
    max-width: 138px;
  }
`;
