import React from 'react';
import styled, { css } from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import Banner from './banner';

export const AllDepartments = ({ isHovered, departments = [], zIndex = 0, imageData, dispatchMetrics }) => (
  <Container isHovered={isHovered} zIndex={zIndex}>
    {isHovered ? (
      <>
        <Inner>
          {departments.map(department => (
            <Section key={department.id}>
              <Title>{department.title}</Title>
              <List>
                {department.children.map(category => (
                  <Item
                    key={category.id}
                    onClick={() =>
                      dispatchMetrics('event:track', {
                        event: 'c:click',
                        action: 'click',
                        category: 'menu-header',
                        label: category.title,
                      })
                    }
                  >
                    <Link to={category.link}>{category.title}</Link>
                  </Item>
                ))}
              </List>
            </Section>
          ))}
        </Inner>

        <Banner image={imageData?.image} alt={imageData?.alt} link={imageData?.imageUrl} />
      </>
    ) : null}
  </Container>
);

const Container = styled.div`
  position: absolute;
  width: calc(100% + 24px);
  height: 552px;
  left: -12px;
  top: 40px;
  padding: 24px;

  background: #fafafa;
  border-radius: 0px 0px 16px 16px;

  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: ${({ zIndex }) => zIndex};

  ${({ isHovered }) =>
    isHovered &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 16px;
`;

const Section = styled.section`
  margin-bottom: 24px;
`;

const List = styled.ul``;

const Title = styled.span`
  display: block;
  font-weight: bold;
  color: #333;
  font-size: 14px;
  line-height: 32px;
  cursor: default;
`;

const Item = styled.li``;

const Link = styled(Nav)`
  display: block;
  color: #333;
  font-size: 14px;
  line-height: 32px;
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
    text-decoration: none;
  }
`;
