import React from 'react';
import { getTarget, getRel, getHighlight } from '../helpers/trending-topics';

export const withTrendingTopics = Components => props => {
  const tts = props?.trendingTopics?.component?.children || [];

  const trendingTopics = tts.map(tt => ({
    highlight: getHighlight(tt),
    flag: tt?.flag || false,
    link: tt?.link || '/',
    title: tt?.title || '',
    target: getTarget(tt),
    rel: getRel(tt),
  }));

  return <Components {...props} trendingTopics={trendingTopics} />;
};

export default withTrendingTopics;
