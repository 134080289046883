import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import SearchSVG from '@catalogo/ui-empresas-desktop-svg/header-search.svg';
import { eventSubmit } from '@catalogo/ui-empresas-mobile-header/src/components/search/helpers';
import { SearchTooltipHandler } from '@catalogo/ui-empresas-desktop-header/src/components/top/search/components/search-tooltip-handler';
import { useHeaderContext } from '@catalogo/service-empresas-header/src/context/header';
import { useKeyboardNavigation } from '@catalogo/service-empresas-header/src/hooks/keyboard-navigation';
import { resetTermSearched } from '@catalogo/service-empresas-header/src/helpers/keyboad-navigation';
import ContextSearch from './components/context-search';

const SearchButton = () => (
  <SearchButtonUI>
    <SearchIcon />
  </SearchButtonUI>
);

const Search = props => {
  const { searchTerm } = props;
  const [typedTerm, setTypedTerm] = useState(null);
  const [hasInputFocus, setHasInputFocus] = useState(false);
  const [hasAlreadyShown, setHasAlreadyShown] = useState(false);
  const inputRef = useRef();
  const { termSelected, context, placeholder, formAction } = useHeaderContext();
  useKeyboardNavigation();

  useEffect(() => {
    inputRef.current.value = termSelected;
  }, [termSelected]);

  useEffect(() => {
    searchTerm ? (inputRef.current.value = searchTerm) : null;
  }, [searchTerm]);

  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 27) {
      onEscape();
    }
  };

  const onEscape = () => {
    inputRef.current.blur();
    setHasInputFocus(false);
  };

  const onFocusInput = () => setHasInputFocus(true);
  const isContextDefault = () => !context || context?.toLowerCase() === 'americanas';
  const getFormAction = () => (isContextDefault() ? '/busca' : formAction);

  return (
    <Container>
      <FormUI onSubmit={evt => eventSubmit(evt)} action={getFormAction()} zIndex={hasInputFocus ? 10 : 0}>
        {context && <ContextSearchUI />}
        <InputUI
          type="text"
          name="conteudo"
          autoComplete="off"
          ref={inputRef}
          onFocus={onFocusInput}
          onBlur={() => {
            setHasAlreadyShown(false);
            setTimeout(() => setHasInputFocus(false), 300);
          }}
          placeholder={placeholder}
          onChange={evt => {
            const { value } = evt.target;
            setTypedTerm(value);
            setHasAlreadyShown(true);
            if (!value) {
              resetTermSearched();
            }
          }}
          onKeyDown={onKeyDown}
        />
        <SearchButton />
      </FormUI>
      {isContextDefault() ? (
        <SearchTooltipHandler
          hasInputFocus={hasInputFocus}
          hasAlreadyShown={hasAlreadyShown}
          setHasInputFocus={setHasInputFocus}
          typedTerm={typedTerm}
        />
      ) : null}
    </Container>
  );
};

export default Search;

const Container = styled.div`
  width: 100%;
  transition: all 0.1s ease-in-out;
  will-change: top;
  flex: 5;
  max-width: 746px;
  padding: 0 15px;
`;

const FormUI = styled.form`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  height: 40px;
  z-index: ${({ zIndex }) => zIndex};
  position: relative;
`;

const InputUI = styled.input`
  outline: none;
  width: 100%;
  background: none;
  border: none;
  padding: 6.4px 64px 6.4px 9.6px;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  color: #666666;
`;

const ContextSearchUI = styled(ContextSearch)`
  display: block;
  border: none;
  width: 100%;
  flex: 3;
`;

const SearchButtonUI = styled.button`
  width: 40px;
  border: none;
  background: none;
  display: flex;
  align-items: center;
`;

const SearchIcon = styled(SearchSVG)`
  fill: ##35002a;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
