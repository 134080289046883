import React from 'react';
import styled from 'styled-components';
import { Wrapper } from '@catalogo/theme-wrapper';
import TrendingTopics from './trending-topics';
import { ConsumptionType } from './consumption-type';

const MiddleHeader = props => (
  <WrapperUI>
    <ConsumptionType {...props} />
    <TrendingTopicsUI {...props} />
  </WrapperUI>
);

export default MiddleHeader;

const WrapperUI = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
`;

const TrendingTopicsUI = styled(TrendingTopics)`
  align-items: center;
  height: 100%;
`;
