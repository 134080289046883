import { localStorage } from '@catalogo/core-local-storage';

export const getSuggestionData = (term = null) => {
  const data = getRecentSearches();
  const keySearch = term ? 'acterm' : 'achistory';

  return data
    ?.reverse()
    ?.slice(0, 3)
    .map(item => {
      const term = item.rc || item.term;
      const path = `/busca?conteudo=${term}&chave_search=${keySearch}`;
      const name = term.replace(/-/g, ' ');
      return { ...item, path, name };
    });
};

export const getRecentSearches = () => localStorage.get('recentSearches') ?? [];

export const eventSubmit = (evt, action) => {
  evt.preventDefault();
  const term = evt.currentTarget.querySelector('input[name=conteudo]').value;
  if (!term) {
    return;
  }
  evt.currentTarget.querySelector('input[name=conteudo]').value = term.trim();
  evt.target.submit();
};

export const callBack = (fn, value) => () => fn(value);
