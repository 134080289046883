import { getIn } from 'seamless-immutable';
import { stringify } from 'query-string';
import { getProductPathname } from '@catalogo/core-product/src';

export const getLink = (product, pfmParams, oneDayDelivery, sellerId, isRec) => {
  const id = getIn(product, ['id'], '');
  const slug = getIn(product, ['slug'], '');
  const queryString = getIn(product, ['queryString'], '');
  const pathname = getProductPathname({ id, slug });
  const offerIdParam = addOfferIdToQueryString(product);
  const oneDayDeliveryParam = getQueryStringOneDayDelivery(oneDayDelivery);
  const queryParams = getQueryString(queryString);
  const sellerIdParam = addSellerIdToQueryString(sellerId);
  const buyboxTokenParam = addBuyboxTokenToQueryString(product);

  if (isRec) {
    return pathname.concat(pfmParams, queryParams);
  }

  return pathname.concat(pfmParams, oneDayDeliveryParam, queryParams, offerIdParam, sellerIdParam, buyboxTokenParam);
};

const getQueryString = queryString => (queryString ? `&${queryString}` : '');

const getQueryStringOneDayDelivery = oneDayDelivery => (oneDayDelivery ? `&${oneDayDelivery}` : '');

const addOfferIdToQueryString = product => {
  const offerId = getIn(product, ['offers', 'result', 0, 'offerId']);
  return offerId ? `&offerId=${offerId}` : '';
};

const addSellerIdToQueryString = sellerId => (sellerId ? `&sellerId=${sellerId}` : '');

const addBuyboxTokenToQueryString = product => {
  const buyboxToken = product?.offers?.meta?.buyboxToken;

  return buyboxToken ? `&buyboxToken=${buyboxToken}` : '';
};

export const getOneDayDelivery = oneDayDeliveryFiltered => {
  if (!oneDayDeliveryFiltered) {
    return '';
  }

  const oneDayDelivery = {
    oneDayDeliveryFiltered: oneDayDeliveryFiltered,
  };

  return stringify(oneDayDelivery);
};

export const getPfmParams = (match, positionCard, pageType) => {
  const params = {
    pfm_index: positionCard + 1,
    pfm_carac: match?.params?.term,
    pfm_page: pageType,
    pfm_pos: 'grid',
    pfm_type: `${pageType}_page`,
  };

  return stringify(params);
};

export const getPfmParamsAds = (positionCard, position, publication, pageType) => {
  const params = {
    pfm_index: positionCard + 1,
    pfm_page: pageType,
    pfm_pos: position,
    pfm_type: 'vit_ads',
    nmOrigem: `${position}-${publication?.title}`,
  };

  return stringify(params);
};

export const getPfmParamsRecs = (positionCard, position, publication, pageType, qsKey) => {
  const params = {
    pfm_index: positionCard + 1,
    pfm_carac: publication?.title,
    pfm_page: pageType,
    pfm_pos: position,
    pfm_type: publication?.rule ? 'vit_recommendation' : 'vit_spacey',
    chave: qsKey,
  };

  return stringify(params);
};

export const getPfmParamsMercado = (match, positionCard, pageType) => {
  const params = {
    pfm_index: positionCard + 1,
    pfm_carac: match?.params?.term,
    pfm_page: pageType,
    pfm_pos: 'grid',
    pfm_type: `${pageType}_page`,
    acomSearch: true,
    acomTerm: match?.params?.term,
  };

  return stringify(params);
};
