import React from 'react';
import styled from 'styled-components';
import AempLogo from '@catalogo/ui-empresas-desktop-svg/logo-main.svg';

const Logo = ({ className }) => (
  <VisibleLogo className={className} title="americanas empresas" href="/">
    <AempLogo fill="#fff" width={268.63} />
  </VisibleLogo>
);

export default Logo;

const VisibleLogo = styled.a`
  display: flex;
  flex: 1;
`;
