import React, { HTMLProps } from 'react';
import { getIn } from 'seamless-immutable';
import { useSuggestions } from '../hooks/autocomplete';
import { fmtSuggestions } from '../helpers/autocomplete';

export interface WithSuggestionsProps {
  term: string;
  skip: boolean;
  sellerId: string;
  hasFocus: boolean;
}

export const withSuggestions = (Component: React.FC) => ({
  term,
  skip = false,
  sellerId,
  hasFocus,
  ...props
}: WithSuggestionsProps & HTMLProps<HTMLSpanElement>) => {
  const { data, loading, previousData } = useSuggestions(term, sellerId, skip);

  const result = data ? data : previousData;
  const suggestions = fmtSuggestions(getIn(result, ['autocomplete', 'suggestions'], []) || []) || [];
  const products = getIn(result, ['autocomplete', 'products'], []) || [];
  const hasProducts = !!products?.length;
  const hasData = !!suggestions.length || hasProducts;
  const show = (!!hasData || loading) && !!term && hasFocus;

  const searchProps = {
    loading,
    suggestions,
    products,
    hasProducts,
    show,
    hasData,
  };

  return <Component {...searchProps} {...props} />;
};

export default withSuggestions;
