import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHeaderContext } from '@catalogo/service-americanas-header/src/context/header';
import { resetTooltipItemSelected } from '@catalogo/service-americanas-header/src/helpers/keyboad-navigation';
import TooltipListItem from './tooltip-list-item';

const SearchTooltip = ({ title, titleFontSize = 16, items, className, type = 'historic' }) => {
  const { tooltipItemSelected, termSearched } = useHeaderContext();
  const shouldResetTooltipItemSelected = () => tooltipItemSelected > items.length - 1 || tooltipItemSelected < 0;

  useEffect(() => {
    if (shouldResetTooltipItemSelected()) {
      resetTooltipItemSelected(termSearched);
    }
  }, [tooltipItemSelected]);

  return (
    <TooltipContent className={className}>
      <TooltipContentTitle titleFontSize={titleFontSize}>{title}</TooltipContentTitle>
      <TooltipList>
        {items.map((item, index) =>
          type === 'suggestion' ? (
            <TooltipListItem
              key={item.name}
              item={item}
              index={index}
              isSelected={index === tooltipItemSelected}
              highlighText={termSearched}
            />
          ) : (
            <TooltipListItem key={item.name} item={item} index={index} isSelected={index === tooltipItemSelected} />
          )
        )}
      </TooltipList>
    </TooltipContent>
  );
};

export default SearchTooltip;

const TooltipContent = styled.div`
  background-color: ${({ theme }) => theme.white};
  padding: 8px 6px 8px 8px;
  position: relative;
  border-radius: 8px;
`;

const TooltipContentTitle = styled.p`
  font-size: ${({ titleFontSize }) => titleFontSize}px;
  font-weight: bold;
  text-transform: lowercase;
  line-height: 20px;
  text-align: left;
  color: ${({ theme }) => theme.grey.dark};
  padding: 10px 8px;
  margin: 0;

  &::first-letter {
    text-transform: capitalize;
  }
`;

const TooltipList = styled.ul`
  padding: 0;
  margin: 0;
`;
