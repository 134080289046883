/* istanbul ignore file */

import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import withHeader from '@catalogo/service-empresas-header/src/hocs/header';
import BlackboxPosition from '@catalogo/core-blackbox/position';
import TopHeader from './components/top';
import MiddleHeader from './components/middle';
import DepartmentMenu from './components/bottom/department-menu-v2';
import LgpdMessageBox from './components/lgpd-message-box';

export const handleScroll = (floatingThreshould, setIsFloating) => () => {
  document.documentElement.scrollTop > floatingThreshould ? setIsFloating(true) : setIsFloating(false);
};

export const Header = ({ floatingThreshould = 0, isFixed = false, ...props }) => {
  const [isFloating, setIsFloating] = useState(false);

  useEffect(() => {
    if (isFixed) {
      document.addEventListener('scroll', handleScroll(floatingThreshould, setIsFloating));
    }
  }, [isFixed]);

  return (
    <>
      <TopBanner>
        <BlackboxPosition position="topBanner" publication={props?.topinho?.component} />
      </TopBanner>
      <HeaderUI>
        <Inner isFloating={isFixed ? isFloating : false}>
          <Container>
            <TopHeader {...props} />
            <MiddleHeader {...props} />
          </Container>
          <DepartmentMenu {...props} />
        </Inner>
        <LgpdMessageBox />
      </HeaderUI>
    </>
  );
};

export default withHeader(Header);

const floatingStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

const staticStyles = css`
  position: static;
`;

const TopBanner = styled.div`
  div[class*='src__Wrapper'] {
    padding-top: 0;
  }

  picture {
    position: static;
  }

  img {
    width: auto;
    margin: auto;
  }
`;

const HeaderUI = styled.header`
  height: 160px;
  position: relative;
  z-index: 4;
  font-family: Hind Madurai, sans-serif;
`;

const Inner = styled.div`
  ${({ isFloating }) => (isFloating ? floatingStyles : staticStyles)};
  background: #f3f3f9;
  width: 100%;
  flex-direction: column;
  display: flex;
  border-bottom: solid #e0e2f0 2px;
`;

const Container = styled.div`
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1260px) {
    padding: 0 30px;
  }
`;
