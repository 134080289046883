/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */

import React, { ComponentType, ReactNode } from 'react';
import { getIn } from 'seamless-immutable';
import compose from 'lodash/fp/compose';
import { withOneDayDelivery } from '@catalogo/core-connect/one-day-delivery';
import { withRouter } from 'react-router';
import { withCookies } from '@catalogo/core-connect/cookies';
import { withMetrics } from '@catalogo/core-metrics';
import { withPageType } from '@catalogo/core-connect/page-type';
import { withConfig } from '@catalogo/core-connect/config';
import { checkIsVibra } from '@catalogo/core-vibra';
import {
  getEventStampUrl,
  getImage,
  isAds,
  isAdsStamps,
  isFastDelivery,
  isInternational,
  isPreSale,
  isRecommended,
  isSuperCashback,
  isUsed,
  oneDayDeliveryMsg,
  shouldRenderFreeFreight,
  isTakeMorePayLess,
} from '../helpers/image-and-stamps';
import { getAdditionalInformation } from '../helpers/sku-variations';
import { getLink, getOneDayDelivery, getPfmParams, getPfmParamsAds, getPfmParamsRecs } from '../helpers/url-builder';
import { getCashBack, getDiscount, getInstallmentPhrase, getPrice, getPromotionalPrice } from '../helpers/price-values';
import { handleMetrics } from '../helpers/metrics';
import { handleDescription } from '../helpers/description';
import { isSellerPage, getSellerId } from '../helpers/seller';
import { formatProductRec } from '../helpers/format-product';

type ObjectType = {
  [key: string]: string;
};

interface WithProductCardProps {
  id: string;
  children: ReactNode;
  product: {
    product: ObjectType;
  };
  oneDayDeliveryFiltered: string;
  positionCard: string;
  match: string;
  position: ObjectType;
  cookies: ObjectType;
  index: string;
  isRec: boolean;
  pageType: string;
  publication: ObjectType;
  trackingUrl: string;
  queryString: string;
  isAd: boolean;
  loading?: boolean;
  error: string;
  config: {
    brand: string;
  };
  dispatchMetrics(): void;
}

export const withProductCard = (Component: ComponentType) => (props: WithProductCardProps) => {
  const {
    id,
    oneDayDeliveryFiltered,
    positionCard,
    match,
    position,
    dispatchMetrics,
    cookies,
    index,
    isRec,
    pageType,
    publication,
    trackingUrl,
    queryString,
    isAd,
    config,
    error,
  } = props;

  const productToFormat = getIn(props, ['product', 'product'], props?.product) || {};
  const sellerId = isSellerPage(pageType) ? getSellerId(productToFormat) : '';
  const objectToFormat = {
    product: { id, ...productToFormat },
    publication,
    position,
    pageType,
    trackingUrl,
    queryString,
    isAd,
  };

  const product = isRec ? formatProductRec(objectToFormat) : productToFormat;
  const isLoading = isRec ? !product?.name && !error : !product?.name;
  const offer = getIn(product, ['offers', 'result', 0], null);

  const isMarketProduct = product?.offers?.result.some((item: ObjectType) => item?.categories?.includes('MARKET'));

  const sendMetrics = () => handleMetrics(product, position, positionCard, dispatchMetrics);
  const pfmParams = () => {
    if (isRec) {
      return getPfmParamsRecs(positionCard ?? index, position, publication, pageType);
    }

    if (isAdsStamps(product)) {
      const { position, positionCard, publication } = product.pfm;
      return getPfmParamsAds(positionCard ?? index, position, publication, pageType);
    }

    return getPfmParams(match, positionCard ?? index, pageType);
  };

  const oneDayDeliveryParam = getOneDayDelivery(oneDayDeliveryFiltered);
  const hasSortimentoVibraTag = product?.tags?.includes('SORTIMENTO_VIBRA');
  const isVibra = checkIsVibra(cookies);
  const outOfStock = !offer;
  const isNurslingProduct = product?.compliance?.nursling;

  const cardProps = {
    isLoading,
    name: product.name,
    rating: product.rating,
    outOfStock,
    showOffer: !outOfStock && ((isVibra && hasSortimentoVibraTag) || !isVibra),
    skuVariations: getAdditionalInformation(product),
    imageSource: getImage(product),
    url: getLink(product, pfmParams(), oneDayDeliveryParam, sellerId, isRec),
    oneDayDeliveryMessage: oneDayDeliveryMsg(product),
    handleClick: sendMetrics,
    description: handleDescription(product),
    seller: offer?.seller,
    isMarketProduct,
    price: {
      installmentPhase: getInstallmentPhrase(product),
      price: getPrice(product, isNurslingProduct),
      promotionalPrice: getPromotionalPrice(product),
      discount: getDiscount(product, isNurslingProduct),
      cashback: getCashBack(product, isNurslingProduct),
    },
    stamps: {
      eventStamp: getEventStampUrl(product, config?.brand, isNurslingProduct),
      isRecommended: isRecommended(product, isNurslingProduct),
      isAd: isRec ? isAdsStamps(product) : isAds(product),
      isPreSale: isPreSale(product),
      isUsed: isUsed(product),
      isSuperCashback: isSuperCashback(product, isNurslingProduct),
      isInternational: isInternational(product),
      isFreeFreight: shouldRenderFreeFreight(product, cookies),
      isFastDelivery: isFastDelivery(product),
      isTakeMorePayLess: !isVibra && isTakeMorePayLess(product),
    },
  };

  const ReturnedComponent = Component as React.FC;

  return <ReturnedComponent {...props} {...cardProps} />;
};

export default compose(
  withConfig,
  withRouter,
  withMetrics,
  withCookies,
  withOneDayDelivery,
  withPageType,
  withProductCard
);
