import React from 'react';
import { fmtDepartmentsData, titleIsDepartment, titleIsAllDepartments, getImageData } from '../helpers/header';

export const withMenu = Component => ({ trendingDepartments, menu, ...props }) => {
  const mainDepartmentsData = menu?.component?.children ?? [];
  const mainDepartmentsDataFmt = fmtDepartmentsData(mainDepartmentsData);
  const departmentsTree = mainDepartmentsDataFmt.find(titleIsDepartment)?.children ?? [];
  const allDepartments = mainDepartmentsDataFmt.find(titleIsAllDepartments)?.children ?? [];

  const trendingDepartmentsData = trendingDepartments?.component?.children ?? [];
  const trendingDepartmentsDataFmt = fmtDepartmentsData(trendingDepartmentsData) ?? [];
  const imageData = getImageData(menu);

  return (
    <Component
      config={props.config}
      history={props.history}
      departmentsTree={departmentsTree}
      allDepartments={allDepartments}
      mainDepartments={mainDepartmentsDataFmt}
      imageData={imageData}
      trendingDepartments={trendingDepartmentsDataFmt}
      {...props}
    />
  );
};

export default withMenu;
