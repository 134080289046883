import React from 'react';
import styled from 'styled-components';

export const Wrapper = props => {
  const { className, color, padding, id, children } = props;
  return (
    <Container className={className} color={color} padding={padding} id={id}>
      {children}
    </Container>
  );
};

const Container = styled.main`
  ${({ color }) => color && `background-color: ${color};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  max-width: 1250px;
  margin: 0 auto;
`;
