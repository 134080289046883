import { getCustomerDataFromCookies } from '@catalogo/core-customer';

export const getCartCookieNameDefault = config => {
  const prefix = { portal: 'prtl', aemp: 'aemp' };

  return `${prefix[config?.brand]}.cart.id`;
};

export const getCartCookieNameWithConsumptionType = (config, cookies) => {
  const types = {
    OWN: `${getCartCookieNameDefault(config)}.own`,
    RESALE: `${getCartCookieNameDefault(config)}.resale`,
  };

  const consumptionType = cookies?.['consumptionType'];

  return types[consumptionType];
};

export const setInitialCartId = ({ config, setCookie, cookies, refetchCart, onMutationComplete }) => cartResult => {
  const defaultCartIdCookieKey = getCartCookieNameDefault(config);
  const cartIdCookieWithConsumptionType = getCartCookieNameWithConsumptionType(config, cookies);
  const cardIdFromCookies = cookies?.[cartIdCookieWithConsumptionType];
  const cartIdFromResult = cartResult?.addCartLines?.cartId;

  if (
    (!cardIdFromCookies && !!cartIdFromResult) ||
    (cardIdFromCookies && !!cartIdFromResult && cartIdFromResult !== cardIdFromCookies)
  ) {
    setCookie(defaultCartIdCookieKey, cartIdFromResult);
    setCookie(cartIdCookieWithConsumptionType, cartIdFromResult);
  }

  refetchCart();
  onMutationComplete();
};

export const getCartCookies = cookies => {
  const { customerId, customerToken } = getCustomerDataFromCookies(cookies);

  return {
    salesChannel: cookies?.b2wChannel,
    opn: cookies?.b2wOpn,
    epar: cookies?.b2wEPar,
    affiliatedID: cookies?.b2wFranq,
    salesSolution: cookies?.salesSolution,
    consumptionType: cookies?.consumptionType,
    partnerInfo: {
      utm_medium: cookies?.['utm_medium'] || cookies?.utmMedium,
      utm_source: cookies?.['utm_source'] || cookies?.utmSource,
      utm_campaign:
        cookies?.['utm_campaing'] || cookies?.utmCampaing || cookies?.['utm_campaign'] || cookies?.utmCampaign,
      utm_content: cookies?.['utm_content'] || cookies?.utmContent,
    },
    ...(!!customerId && { customer: { id: customerId, token: customerToken } }),
  };
};

export const addProduct = ({
  addProductQuery,
  updateProductQuery,
  cartId,
  cartLine,
  setIsUpdateAction,
  sellerStoreId,
  cookies,
}) => items => {
  let offers = items;

  if (!Array.isArray(offers)) {
    offers = {
      offerId: offers?.offerId,
      quantity: offers?.quantity,
      sellerStoreId,
    };
  } else {
    offers = items?.map(offer => ({
      offerId: offer?.offerId,
      quantity: offer?.quantity,
      sellerStoreId,
    }));
  }

  const cookiesParams = getCartCookies(cookies);

  if (cartLine) {
    setIsUpdateAction(true);
    updateProductQuery({
      variables: { cartId, lineId: cartLine?.id, item: offers, ...cookiesParams },
    });
  } else {
    setIsUpdateAction(false);
    addProductQuery({
      variables: { cartId, lines: offers, ...cookiesParams },
    });
  }
};

export const getOfferId = ({ product, productCache, currentSku }) => {
  if (productCache?.offer?.id) {
    return productCache?.offer?.id;
  }

  let current = product?.product ?? product;
  if (currentSku) {
    current = currentSku;
  }

  return current?.offers?.result?.[0]?.offerId ?? current?.offers?.result?.[0]?.id;
};
