import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { bindAll } from '@catalogo/core-bind-all';
import { ErrorImage } from './components/error-image';
import { getPaddingRatio, getDefaultSource, getPaddingTopStyled } from './helpers';

export class LazyPicture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
    bindAll(this, ['handleImageLoaded', 'handleImageError']);
    this.image = React.createRef();
  }

  componentDidMount() {
    const img = this.image.current;
    if (img && img.complete) {
      this.handleImageLoaded();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const prevSource = getDefaultSource(prevProps?.sources);
    const currentSource = getDefaultSource(this.props?.sources);
    if (prevSource?.src !== currentSource?.src) {
      this.checkNewImageData(currentSource, prevState);
    }
  }

  checkNewImageData(currentSource, prevState) {
    const { error: currentStateError } = this.state;
    const { error: prevStateError } = prevState;
    if (prevStateError && currentStateError) {
      const img = new Image();
      img.src = currentSource?.src;
      if (img && img.complete) {
        this.setState({ error: false });
      }
    }
  }

  handleImageError() {
    const { onError } = this.props;
    this.setState({ error: true });
    onError && onError();
  }

  handleImageLoaded() {
    const { onLoad } = this.props;
    onLoad && onLoad();
  }

  renderImage() {
    const { sources, backgroundColor, forcedWidth, lazy, alt } = this.props;

    const defaultSource = getDefaultSource(sources);
    return (
      <Picture ratios={this.getRatios()} forcedWidth={forcedWidth} backgroundColor={backgroundColor}>
        {sources.desktop && <source srcSet={sources.desktop.src} media="(min-width: 1024px)" />}
        <LazyImage
          {...(lazy && { loading: 'lazy' })}
          ref={this.image}
          onLoad={this.handleImageLoaded}
          onError={this.handleImageError}
          src={defaultSource.src}
          backgroundColor={backgroundColor}
          alt={alt}
          fillArea={this.shouldFillArea()}
        />
      </Picture>
    );
  }

  getRatios() {
    const { sources } = this.props;
    return sources;
  }
  renderElement() {
    const { backgroundColor, forcedWidth, sources } = this.props;
    return (
      <Wrapper
        backgroundColor={backgroundColor}
        forcedWidth={forcedWidth}
        paddingTop={{
          mobile: getPaddingTopStyled(sources, 'mobile'),
          desktop: getPaddingTopStyled(sources, 'desktop'),
        }}
      >
        {this.renderImage()}
      </Wrapper>
    );
  }

  shouldFillArea() {
    const { sources } = this.props;
    if (!sources) {
      return {
        mobile: true,
        desktop: true,
      };
    }
    return {
      mobile: !sources['mobile']?.height,
      desktop: !sources['desktop']?.height,
    };
  }

  render() {
    const { sources, errorPlaceholderSize } = this.props;
    const { error } = this.state;

    if (!sources) {
      return null;
    }

    const defaultSource = getDefaultSource(sources);
    if (!defaultSource?.src || error) {
      return <ErrorImage size={errorPlaceholderSize} />;
    }

    return this.renderElement();
  }
}

LazyPicture.defaultProps = {
  lazy: true,
  onLoad: f => f,
};

LazyPicture.propTypes = {
  sources: PropTypes.object,
  onLoad: PropTypes.func,
  backgroundColor: PropTypes.string,
};

const LazyImage = styled.img`
  max-width: 100%;
  display: block;
  margin: 0 auto;
  width: ${({ fillArea }) => (fillArea.mobile ? '100%' : 'auto')};
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      mix-blend-mode: multiply;
    `};
  @media screen and (min-width: 1024px) {
    width: ${({ fillArea }) => (fillArea.desktop ? '100%' : 'auto')};
  }
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
  max-width: 100%;
  padding-top: ${props => props.paddingTop.mobile};
  min-height: 1px;
  width: ${props => (props.forcedWidth ? `${props.forcedWidth}px` : '100%')};
  @media screen and (min-width: 1024px) {
    padding-top: ${props => props.paddingTop.desktop};
  }
`;
const Picture = styled.picture`
  position: ${props => (getPaddingRatio(props.ratios) ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: ${props => (props.forcedWidth ? `${props.forcedWidth}px` : '100%')};
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};
  @media screen and (min-width: 1024px) {
    position: ${props => (getPaddingRatio(props.ratios, 'desktop') ? 'absolute' : 'relative')};
  }

  img[alt='-'] {
    display: none;
  }
`;
