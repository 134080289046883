export const reloadRouteList = ['home'];

export const getUrlParams = (params = '') => {
  const parsedParams = Object.fromEntries(new URLSearchParams(params));
  delete parsedParams?.consumptionType;
  const hasParams = Object.keys(parsedParams)?.length > 0;

  return hasParams ? `?${new URLSearchParams(parsedParams).toString()}` : '';
};

export const setConsumptionType = (history, setCookie, type, routeType) => () => {
  const pathname = history?.location?.pathname;
  const params = getUrlParams(history?.location?.search);
  setCookie('consumptionType', type);
  if (reloadRouteList.includes(routeType?.toLowerCase())) {
    window.location.reload();
  } else {
    history.push(`${pathname}${params}`);
  }
};
