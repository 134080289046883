import { useEffect } from 'react';
import { useHeaderContext } from '@catalogo/service-empresas-header/src/context/header';
import { setHeaderContext } from '@catalogo/service-empresas-header/src/context/header';

export function useKeyboardNavigation() {
  const keyUpArrow = 38;
  const keyDownArrow = 40;

  const { tooltipItemSelected } = useHeaderContext();

  const onKeyDown = event => {
    const { keyCode } = event;
    if (keyCode === keyUpArrow) {
      setHeaderContext({
        tooltipItemSelected: tooltipItemSelected - 1,
      });
    } else if (keyCode === keyDownArrow) {
      setHeaderContext({
        tooltipItemSelected: tooltipItemSelected + 1,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, true);
    return () => {
      document.removeEventListener('keydown', onKeyDown, true);
    };
  }, [onKeyDown]);

  return [tooltipItemSelected];
}
