import { useQuery, useLazyQuery } from '@apollo/client';
import { getHeaderEmpresas, getHeaderEmpresasMenu } from '../queries/header.graphql';

export const useHeader = (variables, skip) => {
  const { loading, data, error } = useQuery(getHeaderEmpresas, {
    variables,
    skip,
  });

  return { loading, data, error };
};

export const useMenu = () => {
  const [fetchMenu, { loading, data, error }] = useLazyQuery(getHeaderEmpresasMenu);

  return { fetchMenu, loading, data, error };
};
