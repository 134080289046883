import { ICookies, Filter } from './types';

export const checkIsVibra = (cookies: ICookies): boolean => cookies?.salesChannel?.toLowerCase() === 'vibra';

export const getVibraFilters = (cookies: ICookies, source: string, path: string): Filter[] => {
  const warehouseId = cookies?.['warehouse.id'];

  if (source === 'blancahotsite') {
    const { 2: tag } = path.split('/');

    return [
      { id: 'variation.warehouse', value: warehouseId, fixed: true, hidden: true },
      { id: 'referer', value: path, fixed: true, hidden: true },
      { id: 'tag-immutable', value: tag, fixed: true, hidden: true },
    ];
  }

  return [
    { id: 'tag', value: 'SORTIMENTO_VIBRA', fixed: true, hidden: true },
    { id: 'variation.warehouse', value: warehouseId, fixed: true, hidden: true },
  ];
};

export const getWarehouseData = (cookies: ICookies) => {
  const region = cookies?.['warehouse.state'];
  const warehouse = cookies?.['warehouse.id'];

  return { region, warehouse };
};

export const getVibraVariablesToTax = (cookies: ICookies) => {
  const isVibra = checkIsVibra(cookies);
  const { region, warehouse } = getWarehouseData(cookies);

  return { isVibra, region, warehouse };
};
