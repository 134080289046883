import React from 'react';
import styled from 'styled-components';
import withTrendingTopics from '@catalogo/service-empresas-header/src/hocs/trending-topics';
import A from '@catalogo/ui-americanas-desktop-svg/a.svg';
import { Nav } from '@catalogo/core-nav';

export const TrendingTopics = ({ trendingTopics }) => (
  <List>
    {trendingTopics.map(tt => (
      <Item key={tt.title}>
        <Link to={tt.link} target={tt.target} rel={tt.rel}>
          {tt.highlight && <Highlight />}
          {tt.title}
        </Link>
      </Item>
    ))}
  </List>
);

export default withTrendingTopics(TrendingTopics);

const List = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;
  margin: 0;
`;

const Item = styled.li`
  display: inline-block;
  font-size: 14px;
  height: 100%;
  width: 100%;
  z-index: 0;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #c2c2c7;
    z-index: -1;
    opacity: 0;
    transform: scaleX(0.1);
    transition: all 0.3s;
  }
  &:hover {
    &:before {
      opacity: 0.5;
      transform: translateZ(0);
    }
  }
`;

const Link = styled(Nav)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #35002a;
  padding: 0 14px;
  text-decoration: none;
  height: 100%;
  width: 100%;
  white-space: nowrap;

  &:hover,
  &:active {
    text-decoration: none;
  }
`;

const Highlight = styled(A)`
  margin-right: 5px;
`;
