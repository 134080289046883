import React from 'react';
import styled from 'styled-components';
import withProductCard from '@catalogo/service-americanas-product-card/src/hocs/product-card';
import InStockCard from './components/organisms/inStockCard';
import OutOfStockCard from './components/organisms/outOfStockCard';

export const ProductCardSuggestion = props => {
  const { showOffer, className } = props;

  return (
    <Wrapper className={className}>
      {showOffer ? <InStockCard {...props} className="" /> : <OutOfStockCard {...props} className="" />}
    </Wrapper>
  );
};

export default withProductCard(ProductCardSuggestion);

const Wrapper = styled.div`
  background-color: #fff;
  overflow: hidden;
  width: 90%;
  height: 100%;
  margin-left: 2px;
`;
